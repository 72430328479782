.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.plans-blur-1 {
  width: 22rem;
  height: 30rem;
  left: 0;
  background: rgba(87, 196, 233, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(212px);
  z-index: -9;
}

.plans-blur-2 {
  width: 22rem;
  height: 30rem;
  right: 0;
  background: rgba(87, 196, 233, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(212px);
  z-index: -9;
}

.plan {
  display: flex;
  flex-direction: column;
  background: rgb(169, 169, 169);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}

.plan:nth-child(2) {
  background: var(--blue);
  transform: scale(1.1);
  font-size: 1rem;
  font-weight: bold;
}

.plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}

.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.plan > :nth-child(5) {
  font-size: 0.8rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.feature > img {
  width: 1rem;
}

.plans > :nth-child(2) > svg {
  fill: white;
}

.plans > :nth-child(2) > button {
  color: orange;
}

@media screen and (max-width: 768px) {
  .plans {
    flex-direction: column;
    border-radius: 10px;
  }

  .plans > :nth-child(2) {
    transform: none;
  }
}
