.Footer-container {
  position: relative;
}

.Footer-container > hr {
  border: 1px solid var(--lightgray);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.social-links {
  display: flex;
  gap: 4rem;
}

.social-links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.blur-f {
  width: 66rem;
  height: 14rem;
  bottom: 0;
  right: 15%;
  background: rgba(87, 196, 233, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(100px);
  z-index: -9;
}
